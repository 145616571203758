export const currencySymbolLocalMap: any = {
    us: '$', // 前面
    ca: '$', // 前
    uk: '£', // 前
    eu: ' €', // 前
    de: ' €', // 后
    fr: ' €', // 后
    it: ' €', // 后
    es: ' €', // 后
    cis: '₽', // 后
    jp: '¥', // 前
    cn: '¥', // 前
    kr: '₩', // 前
    sa: 'د.إ', // 前
    za: 'R', // 前
    au: '$', // 前
    tw: 'NT$', // 前
    hk: 'HK$', //前
    br: 'R$',
};
// 哪些国家货币符号在后
export const currencySymbolBack: string[] = ['de', 'fr', 'it', 'es', 'cis'];
// 默认货币分割符为',';但以下国家货币分割符为'.'
export const splitSymbolForPoint: string[] = ['eu', 'de', 'fr', 'it', 'es'];
export const FormatMoneyLocal = (price: number, locale = 'us') => {
    if (!price) {
        return 0;
    }
    // 欧洲国家逗号','表示小数点, 小数点'.'则表示千分位 如 12.345,98
    const value1 = splitSymbolForPoint.includes(locale) ? price.toString().replace('.', ',') : String(price);
    // 执行千分位
    // const thousandsSep1 = splitSymbolForPoint.includes(locale) ? ',' : '.'; //货币分割符(替换小数点)
    // 运营要求在de和fr把千分位分割符去掉
    const thousandsSep2 = ['de', 'fr'].includes(locale) ? ' ' : splitSymbolForPoint.includes(locale) ? '.' : ','; //货币分割符(千分位分割)
    // const value1 = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSep1); //替换小数点
    const value2 = value1.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSep2); //执行千位分格式化,得到像 12,345.98 这样的值
    // 添加货币符号,有货币符号在前,有货币符号在后
    const currencySymbol = currencySymbolLocalMap[locale]; // 货币符号
    const value3 = currencySymbolBack.includes(locale) ? value2 + currencySymbol : currencySymbol + value2; // 得到 $12,345.98 或者 12,345.98€

    return value3;
};

export const FormatNumber = (number: number) => {
    if (!number) {
        return 0;
    }
    return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
